import React, { useEffect, useState } from "react";
import altruweLgo from "./assets/logo.svg";
import iosLogo from "./assets/appstore_badge.svg";
import androidLogo from "./assets/googleplay_badge.svg";
import "./App.css";
import { fetchData, getMobileOperatingSystem } from "./utils/utils";

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [hasContentId, setHasContentId] = useState(false);
  const [platformType, setPlatformType] = useState("");

  useEffect(() => {
    // Get Content ID
    const contentId = new URL(window.location.href).searchParams.get("id");
    setHasContentId(!!contentId);

    // Fetch Meta
    fetchData(contentId)
      .then((rep) => {
        const metaData = rep;
        setMetaData(metaData);
      })
      .catch((err) => {
        console.error(err);
      });

    const view = getMobileOperatingSystem();
    setPlatformType(view);

    if (view === "iOS" || view === "Android") {
      setIsMobile(true);
      window.location.replace(
        `altruwe-app://CONTENT_READER?articleId=${contentId}`
      );
    } else {
      //Show screen with 2 logos
      setIsMobile(false);
    }
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img src={altruweLgo} className="App-logo" alt="logo" />
        {metaData ? (
          <div className="App-header-text">
            <h2>Redirection ...</h2>
            <p>Ce contenu est propulsé par Altruwe, le réseau social de ceux qui s’engagent pour l’altruisme
              Application gratuite, à but non-lucratif</p>
          </div>
        ) : (
          <div className="App-header-text">
            <h2>Oups ...</h2>
            <p>
              Cet article semble indisponible ... Télécharge l'app pour
              découvrir d'autres contenus !
            </p>
          </div>
        )}
      </header>
      <footer className="App-footer">
        {hasContentId && metaData && (
          <a href={metaData?.url} className={"button button-see links"}>
            Voir le contenu
          </a>
        )}
        {isMobile ? (

          <a
            href={
              platformType === "iOS"
                ? "https://apps.apple.com/ca/app/altruwe/id1581421183"
                : "https://play.google.com/store/apps/details?id=com.altruwe"
            }
            className={"button button-go links"}
          >
            Télécharger l'app
          </a>
        ) : (
          <div className="App-desktop">
            <div className="text-download-container">
              <hr />
              <p className="text-download">Télécharger l'app</p>
              <hr />
            </div>
            <div className="buttons">
              <a href="https://apps.apple.com/ca/app/altruwe/id1581421183">
                <img src={iosLogo} className="logo-store" alt="logo" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.altruwe">
                <img src={androidLogo} className="logo-store" alt="logo" />
              </a>
            </div>
          </div>
        )}
      </footer>
    </div>
  );
}

export default App;
